<template>
  <ok-modal
    :value="value"
    size="lg"
    dialog-class="tables-modal"
    :title="$t('forms.'+(isEdit?'edit':'add'))"
    @change="$emit('input',false)"
  >
    <validation-observer ref="form">
      <error-alert
        v-if="error"
        :error="error"
      />
      <b-row>
        <b-col md="6">
          <ok-text-input
            v-model="city"
            is-required
            :label="$t('fields.city')"
          />
        </b-col>
        <b-col md="6" />
        <b-col
          v-for="(locale,index) in locales"
          :key="'locale'+index"
          md="6"
        >
          <ok-text-input
            v-model="locale.name"
            is-required
            :label="`${$t('branches.zone-name')} (${$t(`locales.${locale.locale}`)})`"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <template #actions>
      <div class="d-flex justify-content-end align-items-center">
        <b-button
          variant="outline-dark"
          :disabled="loading"
          @click="submit"
        >
          <b-spinner
            v-if="loading"
            small
            class="ml-1"
          />
          {{ $t('forms.save') }}
        </b-button>
        <b-button
          variant="dark"
          class="ml-1"
          @click="$emit('input',false)"
        >
          {{ $t('forms.cancel') }}
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import {
  BButton, BRow, BCol,
} from 'bootstrap-vue'
import OkModal from '@/@bya3/components/modals/OkModal'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import { ValidationObserver } from 'vee-validate'
import DeliveryZonesAPIs from '@/api/store/delivery-zone'

export default {
  name: 'ProcessZoneModal',
  components: {
    ErrorAlert,
    OkTextInput,
    ValidationObserver,
    OkModal,
    BButton,
    BRow,
    BCol,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    zone: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      error: null,
      locales: [],
      loading: false,
      city: '',
    }
  },
  computed: {
    isEdit() {
      return !!this.label?.id
    },
  },
  watch: {
    zone() {
      this.error = null
      this.setForm()
    },
  },
  mounted() {
    this.setForm()
  },
  methods: {
    setForm() {
      this.locales = []
      this.userLocales.forEach(loc => {
        const labels = this.zone?.locales ?? []
        const index = labels.findIndex(l => l.locale === loc)
        if (index > -1) {
          this.locales.push(this.zone.locales[index])
        } else {
          this.locales.push({ locale: loc, name: '' })
        }
      })
      this.city = this.zone?.city ?? ''
    },
    async submit() {
      this.error = null
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.error = this.$tc('errors.field-invalid', 2)
        this.notifyError = this.$tc('errors.field-invalid', 2)
      } else {
        this.loading = true
        const form = { locales: JSON.stringify(this.locales), city: this.city }
        if (this.isEdit) {
          form.id = this.zone.id
        }
        await DeliveryZonesAPIs.process(form).then(res => {
          if (res.error === 0) {
            this.$notifySuccess(res.message)
            this.$emit('input', false)
            this.$emit('updated')
            this.$emit('added', res.zone)
          }
        }).catch(e => { this.error = e })
          .finally(() => { this.loading = false })
      }
    },
  },
}
</script>
