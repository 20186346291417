<template>
  <div>
    <ok-table
      ref="datatable"
      :config="config"
      :title="$t('branches.delivery-zones')"
      @edit="openModal"
      @delete="handleDelete"
    >
      <template slot="buttons">
        <b-button
          size="md"
          variant="dark"
          class="mx-md-1"
          @click="openModal()"
        >
          {{ $t('branches.add-zone') }}
        </b-button>
      </template>
      <template
        slot="title-column"
        slot-scope="{props}"
      >
        <b-badge
          variant="primary"
        >
          {{ getTitle(props.row) }}
        </b-badge>
      </template>
    </ok-table>
    <process-zone-modal
      v-model="showZoneModal"
      :zone="selectedZone"
      @updated="refreshTable"
    />
  </div>
</template>

<script>
import { BButton, BBadge } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import LocalizationService from '@/services/localization-service'
import ProcessZoneModal from '@/views/store/delivey-zones/config/ProcessZoneModal'
import DeliveryZonesAPIs from '@/api/store/delivery-zone'
import ZonesTableConfig from './config/zones-table-config'

export default {
  components: {
    ProcessZoneModal,
    OkTable,
    BButton,
    BBadge,
  },
  data() {
    return {
      config: ZonesTableConfig,
      showZoneModal: false,
      selectedZone: {},
    }
  },
  mounted() {
    this.fetchCategories()
  },
  methods: {
    getTitle(item) {
      return LocalizationService.getTranslate(item, 'name', this.selectedLocale)
    },
    refreshTable() {
      this.showZoneModal = false
      this.selectedZone = {}
      this.$refs.datatable.loadItems()
    },
    openModal(data = null) {
      this.showZoneModal = true
      this.selectedZone = data?.row ?? null
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => DeliveryZonesAPIs.delete(data.row.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
  },
}
</script>
