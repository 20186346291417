import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import CommonTableActions from '@/constants/common-table-actions'

const ZonesTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/delivery-zones`,
  rowsPath: 'zones',
  rowClickable: true,
  attachPageId: false,
  columns: [
    {
      label: 'fields.name',
      field: 'title',
    },
    {
      label: 'fields.city',
      field: 'city',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    ...CommonTableActions,
  ],
  filters: [
    {
      label: 'fields.name',
      query: 'name',
      colLength: 3,
    },
    {
      label: 'fields.city',
      query: 'city',
      colLength: 3,
    },
  ],
}

export default ZonesTableConfig
